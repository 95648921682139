

























import Vue, { PropType } from 'vue'

interface Option {
  value: string;
  name: string;
}

export default Vue.extend({
  name: 'SelectInput',
  props: {
    name: {
      type: String,
      default: ''
    },
    options: {
      type: Array as PropType<Array<Option>>,
      default: () => []
    },
    value: {
      type: [String, Number],
      default: ''
    }
  },
  methods: {
    update (value: string): void {
      if (typeof this.value === 'number') {
        this.$emit('input', parseInt(value))
      } else {
        this.$emit('input', value)
      }
    }
  }
})
