

































































































































































































































































import Vue from 'vue'
import TextInput from './TextInput.vue'
import NumberInput from './NumberInput.vue'
import RangeInput from './RangeInput.vue'
import SelectInput from './SelectInput.vue'
import RadioImage from './RadioImage.vue'
import CheckImage from './CheckImage.vue'
import RenderSketch from './RenderSketch.vue'
import { colorConvert } from '../helpers/colors'

// type Placement = 'left' | 'center' | 'right'

export default Vue.extend({
  name: 'Konfigurator',
  components: {
    TextInput,
    NumberInput,
    RangeInput,
    SelectInput,
    RadioImage,
    CheckImage,
    RenderSketch
  },
  data () {
    return {
      scale: 2,
      data: {
        walls: [
          {
            name: 'SSV Glass-Concept',
            key: 'ssv-glass-koncept',
            category: 'koncept',
            noiseCancellation: 35, // Rw dB,
            thickness: 10.38, // mm lamellglas,
            width: { min: 100, std: 800, max: 900 },
            height: { min: 1000, max: 3030 }
          },
          {
            name: 'SSV Glass-Concept',
            key: 'ssv-glass-koncept',
            category: 'koncept',
            noiseCancellation: 40, // Rw dB,
            thickness: 12.38, // mm ljudlamellglas,
            width: { min: 100, std: 800, max: 900 },
            height: { min: 1000, max: 3030 }
          },
          {
            name: 'SSV Glasparti',
            key: 'ssv-klasparti',
            category: 'parti',
            noiseCancellation: 30, // Rw dB,
            thickness: 6, // mm härdat
            width: { min: 200, std: 1300, max: 1400 },
            height: { min: 1000, max: 3030 }
          },
          {
            name: 'SSV Glasparti',
            key: 'ssv-glasparti',
            category: 'parti',
            noiseCancellation: 35, // Rw dB,
            thickness: 44.2, // lamell
            width: { min: 200, std: 1300, max: 1400 },
            height: { min: 1000, max: 3030 }
          },
          {
            name: 'SSV Glasparti',
            key: 'ssv-glasparti',
            category: 'parti',
            noiseCancellation: 40, // Rw dB,
            thickness: 44.2, // lamell
            width: { min: 200, std: 1300, max: 1400 },
            height: { min: 1000, max: 3030 }
          },
          {
            name: 'SSV Glasparti',
            key: 'ssv-glasparti',
            category: 'parti',
            noiseCancellation: 30, // Rw dB,
            thickness: 44.2, // lamell
            width: { min: 200, std: 1300, max: 1400 },
            height: { min: 1000, max: 3030 }
          },
          {
            name: 'SSV Glasparti',
            key: 'ssv-glasparti',
            category: 'parti',
            noiseCancellation: 41, // Rw dB,
            thickness: 44.2, // lamell
            width: { min: 200, std: 1300, max: 1400 },
            height: { min: 1000, max: 3030 }
          },
          {
            name: 'SSV Glasparti',
            key: 'ssv-glasparti',
            category: 'parti',
            noiseCancellation: 42, // Rw dB,
            thickness: 44.2, // lamell
            width: { min: 200, std: 1300, max: 1400 },
            height: { min: 1000, max: 3030 }
          },
          {
            name: 'SSV Glasparti',
            key: 'ssv-glasparti',
            category: 'parti',
            noiseCancellation: 43, // Rw dB,
            thickness: 44.2, // lamell
            width: { min: 200, std: 1300, max: 1400 },
            height: { min: 1000, max: 3030 }
          },
          {
            name: 'SSV Glasparti',
            key: 'ssv-glasparti',
            category: 'parti',
            noiseCancellation: 44, // Rw dB,
            thickness: 44.2, // lamell
            width: { min: 200, std: 1300, max: 1400 },
            height: { min: 1000, max: 3030 }
          },
          {
            name: 'Inget glas',
            key: 'no-glas',
            category: 'no-glas',
            noiseCancellation: 0, // Rw dB,
            thickness: 0, // ljudlamell
            width: { min: 200, max: 50000 },
            height: { max: 3030 }
          }
        ],
        portals: [
          {
            name: 'SSV Alu-Frame',
            key: 'ssv-alu-frame',
            category: 'alu-frame',
            noiseCancellation: [25, 35], // Rw dB (range)
            thickness: 10, // mm härdat
            width: {
              min: 690,
              options: [
                { name: 'M7 (790)', value: 690 },
                { name: 'M8 (790)', value: 790 },
                { name: 'M9 (890)', value: 890 },
                { name: 'M10 (990)', value: 990 }
              ], // M8 =790, M9=890, M10=990
              default: 990
            },
            // height: { min: 1990, default: 2290, max: 2390 } // M21=2090 el. valfri
            height: { min: 2100, default: 2100, max: 2090 }
          },
          {
            name: 'SSV Frameless',
            key: 'ssv-frameless',
            category: 'frameless',
            noiseCancellation: [30, 35, 40], // Rw dB (range)
            thickness: 10, // mm härdat
            width: {
              // fixed: 1390 // mm
              min: 890,
              options: [
                { name: 'M9 (890)', value: 890 },
                { name: 'M10 (990)', value: 990 }
              ],
              default: 990
            },
            // height: { min: 2200, default: 2200, max: 3000, allowedOverMax: true } // 2250-3050mm ( Kan tillverkas högre om så önskas )
            height: { min: 2100, default: 2100, max: 2100, totMin: 2200 }
          },
          {
            name: 'SSV Slide - Enkel',
            key: 'ssv-slide-enkel',
            category: 'slide',
            noiseCancellation: [], // oklassad
            thickness: [6, 8], // mm (range)
            width: {
              min: 700, // mm
              max: 1300,
              default: 990
            },
            height: { },
            weight: 80 // kg
          },
          {
            name: 'SSV Slide - Dubbel',
            key: 'ssv-slide-dubbel',
            category: 'slide',
            noiseCancellation: [], // oklassad
            thickness: [6, 8], // mm (range)
            width: {
              min: 700, // mm
              max: 1300,
              default: 990
            },
            height: { },
            weight: 120 // kg
          },
          /* {
            name: 'SSV Standardportal',
            key: 'ssv-standardportal',
            category: 'standard',
            noiseCancellation: [30, 40], // Rw dB (range)
            thickness: null,
            width: {
              fixed: 1290 // mm (9M dörr)
            },
            height: { min: 1000, max: 3030 }
          }, */
          {
            name: 'SSV Standardportal',
            key: 'ssv-standardportal',
            category: 'standard',
            noiseCancellation: [30, 35, 40], // Rw dB (range
            thickness: null,
            width: {
              // fixed: 1390 // mm (10M dörr)
              min: 890,
              options: [
                { name: 'M9 (890)', value: 890 },
                { name: 'M10 (990)', value: 990 }
              ],
              default: 990
            },
            // height: { min: 2200, default: 2200, max: 3000 }
            height: { min: 2100, default: 2100, max: 2100, totMin: 2200 }
          },
          {
            name: 'SSV Dörrset',
            key: 'ssv-door-set',
            category: 'door-set',
            noiseCancellation: [30, 35, 40], // Rw dB (range
            thickness: null,
            width: {
              // fixed: 1390 // mm (10M dörr)
              min: 790,
              options: [
                { name: 'M8 (790)', value: 790 },
                { name: 'M9 (890)', value: 890 },
                { name: 'M10 (990)', value: 990 }
              ],
              default: 990
            },
            // height: { min: 2200, default: 2200, max: 3000 }
            height: { min: 2100, default: 2100, max: 2100, totMin: 2100 }
          }
        ]
      },
      input: {
        id: 0,
        width: 3000,
        height: 2700,
        glas: 'koncept', // koncept or parti
        faneer: 'vit',
        ncsColor: '', // only if faneer == ncs
        portal: {
          type: null,
          width: 790,
          height: 3030,
          placement: 'left',
          hanging: '',
          socket: '',
          doorstep: 'standard',
          drawer: [],
          implement: []
        },
        other: {
          noiseRequirement: 35, // dB
          customer: null,
          object: null,
          name: null
        }
      },
      options: {
        glas: [
          { name: 'Glaskoncept', value: 'koncept', image: 'products/ssv-glass-koncept.png' },
          { name: 'Glasparti', value: 'parti', image: 'products/ssv-klasparti.png' },
          { name: 'Inget glas', value: 'no-glas', image: 'products/empty.png' }
        ],
        faneer: [
          { name: 'Obehandlad', value: 'vit', color: 'rgba(255, 255, 255)' },
          { name: 'Ek', value: 'ek', image: 'options/franer-ek.png' },
          { name: 'Björk', value: 'bjork', image: 'options/franer-bjork.png' },
          { name: 'Ask', value: 'ask', image: 'options/franer-ask.png' }
        ],
        portal: [
          { name: 'Alu-frame', value: 'alu-frame', image: 'products/ssv-alu-frame.png' },
          { name: 'Frameless', value: 'frameless', image: 'products/ssv-frameless.png' },
          { name: 'Standardportal', value: 'standard', image: 'products/ssv-standardportal.png' },
          { name: 'Slide', value: 'slide', image: 'products/ssv-slide-enkel.png' },
          { name: 'Dörrset', value: 'door-set', image: 'products/ssv-dorr-set.png' },
          { name: 'Ingen portal', value: null, image: 'products/empty.png' }
        ],
        placement: [
          { name: 'Vänster', value: 'left', image: 'options/placement-left.svg' },
          { name: 'Centrerad', value: 'center', image: 'options/placement-center.svg' },
          { name: 'Höger', value: 'right', image: 'options/placement-right.svg' }
        ],
        hanging: [
          { name: 'Vänster', value: 'left', image: 'options/hanging-left.svg' },
          { name: 'Höger', value: 'right', image: 'options/hanging-right.svg' }
        ],
        socket: [
          { name: 'Inget', value: 'none', image: 'options/socket-none.svg' },
          { name: 'G01', value: 'g01', image: 'options/socket-g01.svg' }
        ],
        doorstep: [
          { name: 'Standardtröskel', value: 'standard' },
          { name: 'Tröskelplatta', value: 'plate' },
          { name: 'Trälist', value: 'wood' },
          { name: 'Mekanisk tröskel', value: 'mechanic' }
        ],
        drawer: [
          { name: 'Vänster, 200 mm', value: 'left', image: 'options/drawer-left.svg' },
          { name: 'Höger, 200 mm', value: 'right', image: 'options/drawer-right.svg' }
        ],
        implement: [
          { name: 'Överluftdon', value: 'top', image: 'options/implement-top.svg' },
          { name: 'Sidoluftdon', value: 'side', image: 'options/implement-right.svg' }
        ]
      },
      defaultInput: {},
      tempInput: {},
      currentConfig: 0,
      configList: []
    }
  },
  computed: {
    rgbColor (): string {
      if (this.input.ncsColor === '') return ''
      return colorConvert(this.input.ncsColor, 'rgb')
    },
    getFaneerOptions (): any[] {
      return [...this.options.faneer, { name: 'Kulör (NCS)', value: 'color', color: this.rgbColor }]
    },
    portalWidthOptions (): any[] {
      const portal = this.selectedPortal
      if (portal && portal.width.options) return portal.width.options
      return []
    },
    portalWidthMin (): number {
      const portal = this.selectedPortal
      if (portal && portal.width.min) return portal.width.min
      return 0
    },
    portalWidthMax (): number {
      let max = 0
      const portal = this.selectedPortal
      if (portal && portal.width.options) return Math.max(...portal.width.options.map((o: any) => o.value))
      if (portal && portal.width.fixed) return portal.width.fixed
      if (portal && portal.width.min) max = portal.width.min
      if (portal && portal.width.max) max = portal.width.max
      if (max > this.input.width) max = this.input.width
      return max
    },
    portalHeightMax (): number {
      let max = 0
      const wall: any = this.data.walls.find((p: any) => p.category === this.input.glas)
      if (wall && wall.height.max) max = wall.height.max
      const portal = this.selectedPortal
      if (!this.portalHasHeight) return max
      if (portal && portal.height.max && portal.height.max < max) max = portal.height.max
      if (max > this.input.height) max = this.input.height
      return max
    },
    portalHeightMin (): number {
      const portal = this.selectedPortal
      if (!this.portalHasHeight) return this.input.height
      if (portal && portal.height.min) return portal.height.min
      return 0
    },
    noiseCancellationOptions (): any[] {
      const wals = this.data.walls.filter((p: any) => p.category === this.input.glas || this.input.glas == null)
      let options: any[] = []
      if (this.input.glas !== 'no-glas') {
        wals.forEach((w: any) => {
          if (!options.includes(w.noiseCancellation)) options.push(w.noiseCancellation)
        })
      }
      if (this.input.portal.type != null) {
        const portal = this.data.portals.filter((p: any) => p.category === this.input.portal.type)
        const options2: Array<any> = []
        portal.forEach((p: any) => {
          p.noiseCancellation.forEach((a: any) => {
            if (!options2.includes(a)) options2.push(a)
          })
        })
        if (this.input.glas === 'no-glas') {
          options = options2
        } else {
          options = options.concat(options2).filter((e, i, a) => a.indexOf(e) !== i)
        }
      }
      return options.map((o: number) => { return { name: o + ' db', value: o } })
    },
    selectedPortal (): any {
      return this.data.portals.find((p: any) => p.category === this.input.portal.type) || null
    },
    portalHasHeight (): any {
      const portal = this.selectedPortal
      return !(portal && Object.keys(portal.height).length === 0 && portal.height.constructor === Object)
    },
    portalDrawerOptions (): any {
      if (this.input.portal.type === 'door-set') {
        return this.options.drawer.filter((d: any) => d.value !== this.input.portal.hanging)
      }
      return this.options.drawer
    },
    getConfigs (): any[] {
      return this.configList
    },
    getCurrentConfig (): any {
      return this.getConfigs.find(c => c.id === this.currentConfig)
    },
    saveButtonText (): string {
      let s = 'Spara värden '
      if (this.input.other.customer) s += 'för ' + this.input.other.customer
      if (this.input.other.object && this.input.other.name) {
        s += ' (' + this.input.other.object + ', ' + this.input.other.name + ')'
      } else if (this.input.other.object) {
        s += ' (' + this.input.other.object + ')'
      } else if (this.input.other.name) {
        s += ' (' + this.input.other.name + ')'
      }
      return s.trim()
    },
    sketchMinWidth (): number {
      const useGap = ['parti', 'koncept'].includes(this.input.glas) && ['frameless', 'standard'].includes(this.input.portal.type || '')
      const wallMinValues = this.data.walls.filter((w: any) => w.width.min && w.category === this.input.glas)?.map((w: any) => w.width.min)
      const wallMinValue = wallMinValues.length > 0 ? Math.min(...wallMinValues) : 0
      let portalMinValue = this.input.portal.type != null ? this.input.portal.width + (this.input.portal.drawer ? 400 : 0) : 0
      if (this.input.portal.type === 'standard' && this.input.glas === 'no-glas') {
        return this.input.portal.width + (this.input.portal.drawer.length * 200)
      }
      if (useGap) portalMinValue += 31 // TODO
      // console.log(this.portalWidthMin, portalMinValue)
      const minValue = Math.max(...[wallMinValue, portalMinValue])
      if (minValue > 0) return minValue
      return 1000
    },
    sketchMinHeight (): number {
      const wallMinValues = this.data.walls.filter((w: any) => w.height.min && w.category === this.input.glas)?.map((w: any) => w.height.min)
      const portalMinValues = this.data.portals.filter((w: any) => (w.height.min || w.height.totMin) && w.category === this.input.portal.type)?.map((w: any) => w.height.totMin ? w.height.totMin : w.height.min)
      const wallMinValue = wallMinValues.length > 0 ? Math.min(...wallMinValues) : 0
      const portalMinValue = portalMinValues.length > 0 ? Math.min(...portalMinValues) : 0
      const minValue = Math.max(...[wallMinValue, portalMinValue])
      if (minValue > 0) return minValue
      return 1000
    },
    sketchMaxHeight (): number {
      if (this.input.portal.type === 'door-set') {
        return (this as any).data.portals.find((p: any) => p.key === 'ssv-door-set').height.totMin
      }
      return 50000
    }
  },
  watch: {
    input: {
      handler (value) {
        this.setValidValues()
        this.$emit('updateInput', value)
      },
      deep: true
    },
    'input.portal.type' (type, old) {
      if (type !== old && type === 'frameless') this.input.portal.drawer = ['left', 'right'] as any
      if (type !== old && this.selectedPortal) {
        if (this.selectedPortal.height.default) this.input.portal.height = this.selectedPortal.height.default
        if (this.selectedPortal.width.default) this.input.portal.width = this.selectedPortal.width.default
      }
    },
    configList: {
      handler (value) {
        this.$emit('updateConfigs', value)
      },
      deep: true
    }
  },
  mounted () {
    // this.defaultInput = Object.assign({}, this.input)
    this.defaultInput = JSON.parse(JSON.stringify(this.input))
    this.configList = JSON.parse(localStorage.getItem('configs') || '[]')
    if (this.configList.length > 0) {
      const index = this.configList.findIndex((c: any) => c.id === parseInt(localStorage.getItem('currentConfig') || '0'))
      this.input = this.configList[index > 0 ? index : 0]
    } else {
      this.input.id = new Date().getTime()
    }
    this.currentConfig = this.input.id
    this.setTermp()
    this.$emit('updateData', this.data)
    this.$emit('updateOptions', this.options)
  },
  methods: {
    scaleUp (): void {
      if (this.scale >= 10) return
      this.scale += 0.1
    },
    scaleDown (): void {
      if (this.scale <= 0.4) return
      this.scale -= 0.1
    },
    setValidValues (): void {
      if (!this.noiseCancellationOptions.map(a => a.value).includes(this.input.other.noiseRequirement)) {
        this.input.other.noiseRequirement = Math.max(...this.noiseCancellationOptions.map(a => a.value))
      }
      if (!this.portalWidthOptions.map(a => a.value).includes(this.input.portal.width) && this.portalWidthOptions.length > 0) {
        this.input.portal.width = Math.min(...this.portalWidthOptions.map(a => a.value))
      }
      if (this.input.portal.width > this.portalWidthMax) {
        this.input.portal.width = this.portalWidthMax
      } else if (this.input.portal.width < this.portalWidthMin) {
        this.input.portal.width = this.portalWidthMin
      }
      if (this.input.portal.height > this.portalHeightMax) {
        // this.input.portal.height = this.portalHeightMax
        this.input.portal.height = this.portalHeightMin
      } else if (this.input.portal.height < this.portalHeightMin) {
        this.input.portal.height = this.portalHeightMin
      }
      if (this.input.portal.implement.length > 0 && !['standard', 'frameless'].includes('' + this.input.portal.type)) this.input.portal.implement = []
      if (this.input.portal.drawer.length > 0 && !['frameless', 'standard', 'door-set'].includes('' + this.input.portal.type)) this.input.portal.drawer = []
      // if (this.input.portal.drawer.length > 0 && !['standard'].includes('' + this.input.portal.type)) this.input.portal.drawer = []
      if (this.input.portal.socket !== '' && !['frameless', 'standard', 'door-set'].includes('' + this.input.portal.type)) this.input.portal.socket = ''
      if (this.input.portal.doorstep !== '' && this.input.portal.type === 'slide') this.input.portal.doorstep = ''
      if (this.input.height < this.sketchMinHeight) this.input.height = this.sketchMinHeight
      if (this.input.height > this.sketchMaxHeight) this.input.height = this.sketchMaxHeight
      if (this.input.width < this.sketchMinWidth) this.input.width = this.sketchMinWidth

      if (this.input.portal.hanging.length === 0 && ['alu-frame', 'frameless', 'standard', 'door-set'].includes('' + this.input.portal.type)) this.input.portal.hanging = 'right'
      if (this.input.portal.type === 'door-set') {
        if (this.input.portal.drawer.length > 0 && (this.input.portal.drawer as any).includes(this.input.portal.hanging)) {
          this.input.portal.drawer = []
        }
      }
    },
    saveConfig (): boolean {
      let fail = false
      const fields = [this.input.other.customer, this.input.other.object, this.input.other.name]
      fields.forEach((field: any) => {
        if (!field || field.trim().lenght === 0) {
          fail = true
          this.scrollDown()
        }
      })
      if (fail) {
        alert('Fyll i alla uppgifter under övrigt')
        return false
      }
      if (!this.configList.find((c: any) => c.id === this.input.id) && this.configList.find((c: any) => c.other.name === this.input.other.name)) {
        alert('Namnet på konfigurationen måste vara unikt')
        return false
      }
      const configs: any[] = this.getConfigs
      if (configs.find((c: any) => c.id === this.currentConfig)) {
        const index = configs.findIndex((c: any) => c.id === this.currentConfig)
        configs[index] = this.input
      } else {
        configs.push(this.input)
      }
      this.configList = [...configs] as any
      localStorage.setItem('configs', JSON.stringify(configs))
      this.setTermp()
      this.scrollDown()
      alert('Sparat')
      return true
    },
    loadConfig (id: number, ask = true): void {
      if (JSON.stringify(this.input) !== JSON.stringify(this.tempInput) && JSON.stringify(this.input) !== JSON.stringify(this.defaultInput) && ask) {
        const doIt = confirm('Vill du spara dina nuvarande ändringar först?')
        if (doIt) {
          if (!this.saveConfig()) return
        }
      }
      const configs = this.getConfigs
      if (configs.find((c: any) => c.id === id)) {
        const index = configs.findIndex((c: any) => c.id === id)
        this.input = configs[index]
        this.currentConfig = id
        this.setTermp()
        this.scrollDown()
        localStorage.setItem('currentConfig', id.toString())
      }
    },
    deleteConfig (event: any, id: number): void {
      const configs: any[] = this.getConfigs
      if (configs.find((c: any) => c.id === id)) {
        const doIt = confirm('Är du säker?')
        if (!doIt) return
        const index = configs.findIndex((c: any) => c.id === id)
        configs.splice(index, 1)
        this.configList = [...configs] as any
        localStorage.setItem('configs', JSON.stringify(configs))
        if (this.configList.length > 0) {
          this.loadConfig((this.configList[index > 0 ? index - 1 : 0] as any).id, false)
        } else {
          this.createNewConfig(false)
        }
      }
      event.stopPropagation()
    },
    createNewConfig (ask = true): void {
      if (JSON.stringify(this.input) !== JSON.stringify(this.tempInput) && ask) {
        const doIt = confirm('Vill du spara dina nuvarande ändringar först?')
        if (doIt) {
          if (!this.saveConfig()) return
        }
      }
      // this.input = Object.assign({}, this.defaultInput) as any
      this.input = JSON.parse(JSON.stringify(this.defaultInput))
      this.input.id = new Date().getTime()
      this.currentConfig = this.input.id
      if (this.configList.length > 0) {
        const c: any = this.configList[0]
        this.input.other.customer = c.other.customer
        this.input.other.object = c.other.object
      }
      this.$nextTick(() => this.setTermp())
    },
    scrollDown (): void {
      this.$nextTick(() => {
        const other: any = this.$refs.other
        other.scrollIntoView()
      })
    },
    setTermp (): void {
      this.tempInput = JSON.parse(JSON.stringify(this.input))
    }
  }
})
