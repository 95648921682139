

































































































































































































import Vue from 'vue'
import Ruler from './Ruler.vue'
import { extraInfo } from '../helpers/measures'

export default Vue.extend({
  name: 'RenderSketch',
  components: {
    Ruler
  },
  props: {
    scale: {
      type: Number,
      default: 0.1
    },
    data: {
      type: Object,
      default () {
        return {}
      }
    },
    options: {
      type: Object,
      default () {
        return {}
      }
    },
    input: {
      type: Object,
      default () {
        return {}
      }
    },
    ruler: {
      type: Boolean,
      default: true
    },
    loopkey: {
      type: String,
      default: 'default'
    }
  },
  data () {
    return {
      widthPad: 0.009,
      fontSizePad: 0.4,
      fontSizePad2: 0.3,
      lineHeightPad: 0.7,
      pxToRem: 0.063
    }
  },
  computed: {
    getWallData (): any {
      if (this.input.glas == null) return null
      let wall = this.data.walls.filter((w: any) => w.category === this.input.glas)
      if (this.input.glas !== 'no-glas') wall = wall.filter((w: any) => w.noiseCancellation >= this.input.other.noiseRequirement)
      if (!wall || typeof wall[0] === 'undefined') return null
      return wall[0]
    },
    wallName (): string {
      if (this.getWallData == null) return ''
      return this.getWallData.name
    },
    wallMaxWidth (): number {
      const wall = this.data.walls.find((p: any) => p.category === this.input.glas)
      if (wall && wall.width.max) return wall.width.max
      if (wall && wall.width.std) return wall.width.std
      return 0
    },
    /* wallMaxWidth (): number {
      const portal = this.data.portals.find((p: any) => p.category === this.input.portal.type)
      const portal = this.getPortalData
      if (portal && portal.width.max) return portal.width.max
      if (portal && portal.width.fixed) return portal.width.fixed
      if (portal && portal.width.options) return Math.max(...portal.width.options.map((o: any) => o.value))
      return 0
    }, */
    getPortalData (): any {
      if (this.input.portal == null) return null
      let portal = this.data.portals.filter((p: any) => p.category === this.input.portal.type)
      portal = portal.filter((p: any) => Math.max(...p.noiseCancellation) >= this.input.other.noiseRequirement)
      if (!portal || typeof portal[0] === 'undefined') return null
      return portal[0]
    },
    portalWidth (): number {
      // const portal = this.data.portals.find((p: any) => p.category === this.input.portal.type)
      const portal = this.getPortalData
      if (portal && portal.width.options) return this.input.portal.width
      if (portal && portal.width.fixed) return portal.width.fixed
      if (portal && this.input.portal.width) return this.input.portal.width
      if (portal && portal.width.min) return portal.width.min
      return 0
    },
    portalAndDrawerWidth (): number {
      return this.portalWidth + (this.input.portal.drawer.length > 0 ? this.input.portal.drawer.length * 200 : 0)
    },
    rulerWidth (): number {
      let w = this.wallWidth().width * (this.wallWidth().total * 2)
      // const el: any = this.$refs.sketch
      // const el: any = document.querySelector('#sketch')
      // if (el && (w * this.widthPad) * this.scale < el.clientWidth * this.pxToRem) w = el.clientWidth
      w = w * 2
      return w
    },
    wallHeight (): any {
      if (this.getWallData == null) return 0
      const total = Math.ceil(this.input.height / this.getWallData.height.max)
      let height = this.input.height / total
      height = Math.floor(height)
      return { height, total }
    },
    renderData (): Array<Array<any>> {
      const data = []
      for (let row = 0; row < this.wallHeight.total; row++) {
        const datarow = []
        const wall = this.wallWidth(this.getPortalData && row === this.wallHeight.total - 1)

        let portalCol = null
        if (this.getPortalData && this.input.portal.placement === 'center') portalCol = Math.floor((wall.total - 1) / 2)
        if (this.getPortalData && this.input.portal.placement === 'left') portalCol = 0
        if (this.getPortalData && this.input.portal.placement === 'right') portalCol = wall.total - 1
        const bottomCol = this.wallHeight.total - 1
        // Visa om: glas = Glasparti eller Glaskoncept och portal = frameless eller standard
        // const useGap = ['parti', 'koncept'].includes(this.input.glas) && ['frameless', 'standard'].includes(this.input.portal.type)

        for (let col = 0; col < wall.total; col++) {
          const type = row === bottomCol && col === portalCol ? 'portal' : 'wall'
          const d: any = {
            row,
            // col: (useGap && row === bottomCol && portalCol !== null && this.input.portal.placement === 'left') ? col + 1 : col,
            col: this.useGap.left ? col + 1 : col,
            type,
            width: type === 'portal' ? this.portalAndDrawerWidth : wall.width,
            height: this.wallHeight.height,
            title: type === 'portal' ? this.getPortalData.name : this.getWallData.name
          }
          if (d.width <= 0) {
            continue
          }
          const partiGap = {
            row,
            // col: (this.input.portal.placement === 'right') ? col + 1 : col,
            col: col === 0 ? 0 : (this.useGap.left ? wall.total + 1 : wall.total),
            type: 'gap',
            placement: col === 0 ? 'left' : 'right',
            width: 15,
            height: this.wallHeight.height,
            label: ''
          }
          if (type === 'portal') {
            d.portal = {
              type: this.input.portal.type,
              width: this.portalWidth,
              height: this.input.portal.height,
              label: this.getPortalData.width.options ? this.getPortalData.width.options.find((o: any) => o.value === this.input.portal.width).name : null
            }
            // if (useGap && this.input.portal.placement === 'left') datarow.push(partiGap)
          }
          /*
          if (useGap && portalCol != null) {
            // portal placering vänster = +15mm vänster om dörr, dra av -15mm på glaset till höger om dörr
            if (col === portalCol + 1 && this.input.portal.placement === 'left') {
              d.width -= 15
            }
            // portal placering höger = +15mm höger om dörr, dra av -15mm på glaset till vänster om dörr
            if (col === portalCol - 1 && this.input.portal.placement === 'right') {
              d.width -= 15
            }
            // Om Glasparti = -15mm på glaset längst till vänster eller höger om det är sista glaset.
            if (this.input.portal.type === 'parti') {
              // if (col === 0) d.width -= 15
              // if (col === portalCol - 1) d.width -= 15
            }
          }
          */
          if (this.useGap.left && col === 0) datarow.push(partiGap)
          datarow.push(d)
          if (this.useGap.right && col === wall.total - 1) datarow.push(partiGap)
          // if (useGap && type === 'portal' && this.input.portal.placement === 'right') datarow.push(partiGap)
        }
        data.push(datarow)
      }
      return data
    },
    isDebug (): boolean {
      const uri = window.location.search.substring(1)
      const params = new URLSearchParams(uri)
      return params.get('debug') !== null
    },
    info (): string {
      return extraInfo(this.input, this.renderData, this.options)
    },
    useGap (): any {
      let useGap = ['parti', 'koncept'].includes(this.input.glas) && ['frameless', 'standard'].includes(this.input.portal.type)
      if (this.input.glas === 'koncept' && this.input.portal.placement === 'center') useGap = false
      if (this.input.glas === 'parti' && this.input.portal.type == null) useGap = true
      return {
        use: useGap,
        left: useGap && !(this.input.glas === 'koncept' && this.input.portal.placement === 'right'),
        right: useGap && !(this.input.glas === 'koncept' && this.input.portal.placement === 'left')
      }
    }
  },
  watch: {
    renderData: {
      handler (value) {
        this.$emit('updateData', value)
      },
      deep: true
    }
  },
  methods: {
    wallWidth (hasportal = true): any {
      if (this.getWallData == null || this.wallMaxWidth <= 0) return { width: 0, total: 0 }
      let maxwidth = this.input.width
      if (hasportal) maxwidth -= this.portalAndDrawerWidth
      // const useGap = ['parti', 'koncept'].includes(this.input.glas) && ['frameless', 'standard'].includes(this.input.portal.type)
      if (this.useGap.left) maxwidth -= 15
      if (this.useGap.right) maxwidth -= 15

      let total = Math.ceil(maxwidth / this.wallMaxWidth)
      if (total < 0) return { width: 0, total: 0 }
      let width = Math.ceil(maxwidth / total)
      if (hasportal) total += 1

      if (this.getPortalData && this.input.portal.placement === 'center') {
        let count = 2
        while (maxwidth / count > this.wallMaxWidth) {
          count += 2
        }

        width = maxwidth / count
        total = count
        if (hasportal) total += 1
      }
      width = Math.floor(width)
      return { width, total }
    },
    placementRight (col: any) {
      if (col.type !== 'portal') return false
      if (this.input.portal.type !== 'frameless') return this.input.portal.hanging === 'left'
      if (this.input.portal.type === 'frameless') return this.input.portal.hanging === 'right'
      return false
    }
  }
})
