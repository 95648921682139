










































import Vue from 'vue'
import Konfigurator from './components/Konfigurator.vue'
import SubmitModal from './components/SubmitModal.vue'

export default Vue.extend({
  name: 'App',
  components: {
    Konfigurator,
    SubmitModal
  },
  data () {
    return {
      data: {},
      options: {},
      input: {},
      configs: [],
      showModal: false
    }
  },
  methods: {
    updateData (data: any): void {
      this.data = data
    },
    updateOptions (options: any): void {
      this.options = options
    },
    updateInput (input: any): void {
      this.input = input
    },
    updateConfigs (configs: Array<any>): void {
      this.configs = configs as any
    },
    displayModal (): void {
      if (this.configs.length === 0) {
        alert('Spara en konfiguration först')
        return
      }
      this.showModal = !this.showModal
    },
    closeModal (): void {
      this.showModal = false
    }
  }
})
