import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import VueInputAutowidth from 'vue-input-autowidth'
import axios from 'axios'
import VueAxios from 'vue-axios'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSearchPlus, faPlusSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import App from './App.vue'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    Vue,
    dsn: 'https://fb0868593c1a4f069c2dbfb15e0a9cd3@sentry.duva.se/25',
    autoSessionTracking: true,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [
          'localhost',
          'konfigurator.svenskasystemvaggar.se',
          'api.konfigurator.svenskasystemvaggar.se',
          'konfigurator.svenskasystemvaggar.duvadev.se',
          'api.konfigurator.svenskasystemvaggar.duvadev.se',
          /^\//]
      })
    ],
    tracesSampleRate: 1.0
  } as Sentry.BrowserOptions)
}

library.add(faSearchPlus, faPlusSquare, faMinusSquare)

Vue.component('FontAwesomeIcon', FontAwesomeIcon)

Vue.config.productionTip = false

Vue.use(VueInputAutowidth)
Vue.use(VueAxios, axios)

new Vue({
  render: h => h(App)
}).$mount('#app')
