


























import Vue from 'vue'

export default Vue.extend({
  name: 'RenderSketch',
  props: {
    scale: {
      type: Number,
      default: 0.1
    },
    width: {
      type: Number,
      default: 0
    },
    step: {
      type: Number,
      default: 100
    }
  },
  data () {
    return {
      fontSizePad: 0.4,
      lineHeightPad: 0.7,
      widthPad: 0.009
    }
  },
  computed: {
    getStep (): number {
      let num = this.step * this.scale
      let r = this.scale <= 1.2 ? 200 : 100
      if (this.scale <= 1) r = 300
      if (this.scale <= 0.8) r = 400
      if (this.scale <= 0.5) r = 500
      if (this.scale <= 0.3) r = 600
      num = Math.ceil(num / r) * r
      return num
    }
  },
  methods: {
    getNumbers (start: number, stop: number, step: number): Array<number> {
      return new Array(Math.round(stop / step)).fill(start).map((n, i) => (i) * step)
    }
  }
})
