
























































































































































import Vue from 'vue'
import RenderSketch from './RenderSketch.vue'
// import html2canvas from 'html2canvas'
import { extraInfo } from '../helpers/measures'
import cloneDeep from 'lodash.clonedeep'

export default Vue.extend({
  name: 'SubmitModal',
  components: {
    RenderSketch
  },
  props: {
    configs: {
      type: Array,
      default () {
        return []
      }
    },
    data: {
      type: Object,
      default () {
        return {}
      }
    },
    options: {
      type: Object,
      default () {
        return {}
      }
    },
    input: {
      type: Object,
      default () {
        return {}
      }
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      name: null,
      email: null,
      phone: null,
      date: null,
      comments: null,
      currentInput: this.input,
      currentOutput: [],
      scale: 1.7,
      widthPad: 0.009,
      print: false,
      loading: false,
      imgs: []
    }
  },
  computed: {
    getConfigs (): any[] {
      return this.configs
    },
    getCurrentInput (): any[] {
      return this.currentInput
    },
    getCurrentSize (): any {
      const width = this.currentInput.width as number
      const height = this.currentInput.height as number
      const widthRem = ((width * this.widthPad) * this.scale) + 20
      const heightRem = ((height * this.widthPad) * this.scale) + 20
      const widthPx = widthRem * 16
      const heightPx = heightRem * 16
      return {
        mm: {
          width,
          height
        },
        rem: {
          width: Math.round(widthRem),
          height: Math.round(heightRem)
        },
        px: {
          width: Math.round(widthPx),
          height: Math.round(heightPx)
        }
      }
    },
    getURL (): string {
      return process.env.VUE_APP_BACKEND_API_URL ? process.env.VUE_APP_BACKEND_API_URL : window.location.origin + '/submit.php'
    }
  },
  methods: {
    async submit (event: any) {
      event.preventDefault()
      if (this.loading) return
      this.loading = true
      const formData = new FormData(event.target)
      const data = Object.fromEntries(formData)
      data.configs = await this.images() as any
      /*
      const imgg: any[] = await this.images() as any
      let html = '<!DOCTYPE html><html><head><meta charset="utf-8"><meta name="viewport" content="width=device-width,initial-scale=1.0">'
      Array.from(document.querySelectorAll('style')).forEach((s: any) => {
        html += s.outerHTML
      })
      html += '<style>html, body { overflow-y: unset !important; } .sketch-head .column div, .right-numbers-inner, .bottom-numbers, .bottom-numbers span { font-size: 0.8rem !important; line-height: 1.4rem !important; }</style>'
      html += '</head><body><div id="app">'
      html += imgg[0].image
      // html += '<h1>SHOO</h1>'
      html += '</div></body></html>'
      html = html.replace(/"\/img\//ig, '"' + window.location.origin + '/img/')
      html = html.replace(/url\(\/fonts\//ig, 'url(' + window.location.origin + '/fonts/')
      console.log(html)
      // if (event) return
      this.axios.post(
        'http://0.0.0.0:5000/direct',
        { html: html },
        {
          headers: {
            'content-type': 'application/json'
          }
        }
      ).then(result => {
        console.log(result)

        const pom = document.createElement('a')
        pom.setAttribute('href', 'data:application/pdf,' + result)
        pom.setAttribute('download', 'test.pdf')
        if (document.createEvent) {
          const event = document.createEvent('MouseEvents')
          event.initEvent('click', true, true)
          pom.dispatchEvent(event)
        } else {
          pom.click()
        }
      })
      */
      this.axios.post(
        event.target.action,
        data,
        { withCredentials: true }
      ).then(result => {
        this.loading = false
        if (result.data.error) {
          alert(result.data.error)
          return
        } else {
          this.close()
          alert('Tack för din förfrågan! Vi hör av oss så fort som möjligt.')
        }
        this.name = null
        this.email = null
        this.phone = null
        this.date = null
        this.comments = null
      })
    },
    close (): void {
      this.$emit('close', true)
    },
    image (config: any) {
      // const width = Math.round((((config.width * this.widthPad) * this.scale) + 15) * 16)
      // const height = Math.round((((config.height * this.widthPad) * this.scale) + 15) * 16)
      return new Promise(resolve => {
        this.currentInput = config
        this.print = true
        this.$nextTick(async () => {
          /*
          const el: any = this.$refs.printbox
          const img = (await html2canvas(el, {
            onclone: (doc: any) => {
              if (doc) doc.querySelector('.printbox').style.opacity = 1
            },
            width,
            height
          })).toDataURL()
          */
          const d: any = document
          const html = d ? this.addHtml(d.querySelector('#printbox').outerHTML) : ''
          const extra = await this.getExtraInfo(config)
          const size = this.getCurrentSize
          this.currentInput = {}
          this.print = false
          resolve(Object.assign(cloneDeep(config.other), {
            // image: img,
            html: html,
            info: extra,
            size
          }))
        })
      })
    },
    async images () {
      const arr = []
      for (const config of this.configs) {
        arr.push(await this.image(config))
      }
      return arr
    },
    sketchData (value: any) {
      this.currentOutput = value
    },
    async getExtraInfo (config: any) {
      return new Promise(resolve => {
        let extra = ''
        while (this.currentOutput.length === 0) {
          // TODO
        }
        // extra = this.extraInfo(config)
        extra = extraInfo(config, this.currentOutput, this.options)
        this.currentOutput = []
        resolve(extra)
      })
    },
    addHtml (image: string): string {
      let html = '<!DOCTYPE html><html><head><meta charset="utf-8"><meta name="viewport" content="width=device-width,initial-scale=1.0">'
      Array.from(document.querySelectorAll('style, link')).forEach((s: any) => {
        html += s.outerHTML
      })
      // html += document.querySelector('head')?.outerHTML
      html += '<style>html, body { overflow-y: unset !important; } .sketch-head .column div, .right-numbers-inner, .bottom-numbers, .bottom-numbers span { font-size: 0.8rem !important; line-height: 1.4rem !important; }</style>'
      html += '</head><body><div id="app">'
      html += image
      html += '</div></body></html>'
      html = html.replace(/"\/img\//ig, '"' + window.location.origin + '/img/')
      html = html.replace(/url\(\/fonts\//ig, 'url(' + window.location.origin + '/fonts/')
      html = html.replace(/"\/css\//ig, '"' + window.location.origin + '/css/')
      html = html.replace(/"\/js\//ig, '"' + window.location.origin + '/js/')
      return html
    }
  }
})
