const hues: Record<string, number> = {
  r: 0,
  y: 60,
  g: 120,
  b: 240
}

const ncsRgx = /^(.* )?\d{4}-[rgby](\d{2}[rgby])?$/

const ncs2hsv = (ncs: string) => {
  ncs = ncs.toLowerCase()
  let h: number, v: number, p1: number, p2: number, frac: number

  if (!ncsRgx.test(ncs)) {
    const err = 'NCS color should be in the form\n####-(RGBY)[##(RGBY)]'
    throw err
  }

  // strip irrelevant part
  const lastSpace = ncs.lastIndexOf(' ')
  if (lastSpace !== -1) {
    ncs = ncs.substring(lastSpace + 1)
  }

  // extract darkness (opposite of value, in 0-100)
  v = parseInt(ncs.substring(0, 2), 10)
  v = (100 - v)

  // saturation, in 0-100
  const s = parseInt(ncs.substring(2, 4), 10)

  // hue from primitive color(s)
  const h1 = ncs.substring(5)
  if (h1.length === 1) {
    h = hues[h1]
  } else {
    p1 = hues[h1.charAt(0)]
    p2 = hues[h1.charAt(3)]
    frac = parseInt(h1.substring(1, 3), 10) * 0.01
    h = p1 * frac + p2 * (1 - frac)
    h = Math.round(h)
  }
  return [h, s, v] // 0-360, 0-100, 0-100
}

const hsv2rgb = (h: number, s: number, v: number) => { // here s and v are 0-1
  let r = 0
  let g = 0
  let b = 0
  h = h % 360
  if (s === 0) { // achromatic
    r = v
    g = v
    b = v
  } else { // chromatic color
    const hTemp = h / 60 // h is now in [0,6]
    const i = Math.floor(hTemp) // largest integer <= h
    const f = hTemp - i // fractional part of h
    const p = v * (1 - s)
    const q = v * (1 - (s * f))
    const t = v * (1 - (s * (1 - f)))
    switch (i) {
    case 0: r = v; g = t; b = p; break
    case 1: r = q; g = v; b = p; break
    case 2: r = p; g = v; b = t; break
    case 3: r = p; g = q; b = v; break
    case 4: r = t; g = p; b = v; break
    case 5: r = v; g = p; b = q; break
    }
  }
  return [
    Math.round(r * 255),
    Math.round(g * 255),
    Math.round(b * 255)
  ] // channels in [0, 1]->[0, 255]
}

const rgb2hex = (rgb: Array<number>): string => {
  const rgbnum: number = rgb[2] | (rgb[1] << 8) | (rgb[0] << 16)
  let rgbstr = rgbnum.toString(16)
  while (rgbstr.length < 6) {
    rgbstr = '0' + rgbstr
  }
  return '#' + rgbstr
}

const colorConvert = (ncs: string, type = 'rgb'): string => {
  let hsv: number[]

  try {
    hsv = ncs2hsv(ncs)
  } catch (ex) {
    // alert(ex)
    // console.log(ex)
    return ''
  }

  const rgb = hsv2rgb(hsv[0], hsv[1] * 0.01, hsv[2] * 0.01)
  const hex = rgb2hex(rgb).toUpperCase()

  if (type === 'rgb') return 'rgb(' + rgb.join(', ') + ')'
  if (type === 'hex') return hex
  return 'hsv(' + hsv.join(', ') + ')'
}

export { colorConvert }
