









































import Vue, { PropType } from 'vue'

interface Option {
  value: string;
  name: string;
  image: string;
  color: string;
}

export default Vue.extend({
  name: 'CheckboxImage',
  props: {
    name: {
      type: String,
      default: ''
    },
    options: {
      type: Array as PropType<Array<Option>>,
      default: () => []
    },
    value: {
      type: Array as PropType<Array<string>>,
      default: () => []
    },
    small: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    columnsize: {
      type: Number,
      default: 4
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    update (val: string): void {
      const value: any = this.value
      if (value.includes(val)) {
        value.splice(value.indexOf(val), 1)
      } else {
        value.push(val)
      }
      this.$emit('input', value)
    }
  }
})
