const extraInfo = (config: any, currentOutput: Array<Array<any>>, options: any): string => {
  if (Object.keys(config).length === 0 || Object.keys(options).length === 0) return ''
  const table: any[] = currentOutput.reduce((a: any, b: any) => a.concat(b), [])
  // const rows = [...new Set(table.map((item: any) => item.row))].length
  const cols = [...new Set(table.map((item: any) => item.col))].length
  // const portalPos = table.find((item: any) => item.type === 'portal')
  const portalWidth = (config.portal.type != null) ? config.portal.width : 0
  const portalAndDrawerWidth = portalWidth + (config.portal.drawer.length > 0 ? config.portal.drawer.length * 200 : 0)
  const s = []
  const glas: any[] = []
  if (config.glas === 'koncept') {
    const tmpglas: any[] = []
    let tape = 0
    const joints: number[] = []
    let prevRow = -1
    let prevType = ''
    // table.forEach((item: any, index: number) => {
    table.forEach((item: any) => {
      if (prevRow !== item.row) {
        prevType = ''
        joints[item.row] = 0
      }
      // const wallCols = [...new Set(table.filter((i: any) => i.row === item.row && i.type === 'wall').map((i: any) => i.col))].length
      if (item.type === 'wall') {
        // Glasbredd = (glasBredd - 25mm) - (“linjer mellan glas” * 2mm)
        // Glashöjd = (glasHöjd - 30mm)
        // let w = item.width - 25
        const w = item.width
        const h = item.height - 30
        // height
        /*
        if (item.row > 0 && item.row < rows - 1) {
          h -= 4
        } else if ((item.row === 0 || item.row === rows - 1) && item.row > 2) {
          h -= 2
        }
        */

        // width
        if (prevType === 'wall') {
          // w -= 2
          if (item.type === 'wall') {
            tape += item.height
            joints[item.row]++
            // w -= 2
          }
        }
        // if (table[index + 1] && table[index + 1].type === 'wall') w -= 2
        // let j = joints(table, item.row)

        const i = tmpglas.findIndex(m => m.width === w && m.height === h && m.row === item.row)
        if (i >= 0) {
          tmpglas[i].count++
          return
        }
        tmpglas.push({ width: w, height: h, count: 1, title: item.title, row: item.row })
      }
      prevRow = item.row
      prevType = item.type
    })
    tmpglas.forEach((item: any) => {
      const count = (config.portal.type === 'frameless' && config.portal.placement === 'center') ? item.count - 1 : item.count
      // -25 mm
      item.width -= 25 / count
      // -2mm för varje skarv
      item.width -= (joints[item.row] * 2) / item.count
      glas.push(item)
    })
    if (config.portal.placement === 'center') {
      s.push('<b>Ram + Lock:</b> ' + ((config.height * 2) + ((config.width - portalAndDrawerWidth)) / 2) + ' mm ')
      s.push('<b>Ram + Lock:</b> ' + ((config.height * 2) + ((config.width - portalAndDrawerWidth)) / 2) + ' mm ')
    } else {
      s.push('<b>Ram + Lock:</b> ' + ((config.height * 2) + (config.width - portalAndDrawerWidth)) + ' mm ')
    }
    s.push('<b>U – Profil:</b> ' + (config.width - portalAndDrawerWidth) + ' mm (' + Math.ceil((config.width / 1000) / 3) + ' längder)')
    s.push('<b>Tejp:</b> ' + tape + ' mm (' + Math.ceil((tape / 1000) / 16) + ' rullar)')
    if (config.portal.type !== 'alu-frame') {
      // TODO.. ?
    }
  } else if (config.glas === 'parti') {
    table.forEach((item: any) => {
      if (item.type === 'wall') {
        let w = item.width
        let h = item.height
        if (config.portal.type === 'door-set') {
          h = item.height - 11
        } else {
          h = item.height - 15
        }
        if (item.col === 0 || item.col === cols - 1) {
          w -= 15
        }
        const i = glas.findIndex(m => m.width === w && m.height === h)
        if (i >= 0) {
          glas[i].count++
          return
        }
        glas.push({ width: w, height: h, count: 1, title: item.title })
      }
    })
  }
  glas.forEach((m: any) => {
    s.push('<b>' + m.title + '</b> - ' + m.width + ' x ' + m.height + ' mm (' + m.count + ' st)')
  })
  if (config.portal.type !== null) {
    const portalWallHeight = table.find((t: any) => t.type !== 'wall').height
    s.push('<b>Portal:</b> ' + options.portal.find((h: any) => h.value === config.portal.type).name)
    if (config.portal.type === 'frameless') {
      s.push('<b>Karm:</b> 1390 x ' + (portalWallHeight - 15) + ' mm')
      s.push('<b>Dörr:</b> ' + config.portal.width + ' x ' + config.portal.height + ' mm')
    } else if (config.portal.type === 'standard') {
      s.push('<b>Karm:</b> ' + config.portal.width + ' x ' + (portalWallHeight - 15) + ' mm')
      s.push('<b>Dörr:</b> ' + config.portal.width + ' x ' + config.portal.height + ' mm')
      if (config.portal.drawer.length > 0) s.push('<b>Sidolåda:</b> 200 x ' + (portalWallHeight - 15) + ' mm (' + (config.portal.drawer.length) + ' st)')
    } else if (config.portal.type === 'alu-frame') {
      s.push('<b>Karm:</b> ' + config.portal.width + ' x ' + (portalWallHeight + 10) + ' mm')
      s.push('<b>Dörr:</b> ' + config.portal.width + ' x ' + (config.portal.height + 12) + ' mm')
    } else if (config.portal.type === 'door-set') {
      s.push('<b>Karm:</b> ' + config.portal.width + ' x ' + (portalWallHeight - 15) + ' mm')
      s.push('<b>Dörr:</b> ' + (config.portal.width - 4) + ' x ' + (config.portal.height - 11) + ' mm')
      if (config.portal.drawer.length > 0) s.push('<b>Sidolåda:</b> 200 x ' + (portalWallHeight - 11) + ' mm (' + (config.portal.drawer.length) + ' st)')
    }
    if (config.portal.drawer.length > 0 && (config.portal.type !== 'standard' && config.portal.type !== 'door-set')) s.push('<b>Sidolåda:</b> 200 x ' + portalWallHeight + ' mm (' + (config.portal.drawer.length) + ' st)')
    if (config.portal.hanging) s.push('<b>Hängning:</b> ' + options.hanging.find((h: any) => h.value === config.portal.hanging).name)
    if (config.portal.socket && config.portal.socket !== 'none') s.push('<b>Glasurtag:</b> ' + options.socket.find((h: any) => h.value === config.portal.socket).name)
    if (config.portal.implement.length > 0) s.push('<b>Don:</b> ' + options.implement.filter((h: any) => config.portal.implement.includes(h.value)).map((h: any) => h.name).join(', '))
    if (config.portal.doorstep) s.push('<b>Tröskel:</b> ' + options.doorstep.find((h: any) => h.value === config.portal.doorstep).name)
  }
  if (config.other.noiseRequirement > 0) s.push('<b>Ljudklass:</b> ' + config.other.noiseRequirement + ' db')
  if (config.ncsColor || config.faneer) s.push('<b>Kulör:</b> ' + (config.faneer === 'color' ? config.ncsColor : options.faneer.find((h: any) => h.value === config.faneer).name))
  return s.join('<br />\n')
}
/*
const joints = (table: any[], row: number) => {
  let joints = 0
  let prevRow = -1
  let prevType = ''
  table.filter((item: any) => item.row === row).forEach((item: any, index: number) => {
    if (prevType === 'wall' && item.type === 'wall') joints++
    prevRow = item.row
    prevType = item.type
  })
  return joints
}
*/
export { extraInfo }
