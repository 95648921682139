




































import Vue from 'vue'

export default Vue.extend({
  name: 'NumberInput',
  props: {
    name: {
      type: String,
      default: ''
    },
    value: {
      type: Number,
      default: 0
    },
    suffix: {
      type: String,
      default: ''
    },
    maxlength: {
      type: Number,
      default: 10
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: null
    }
  },
  methods: {
    keyup (value: string): void {
      const num = this.parseNum(value)
      if (num < this.min) return
      if (num > this.max) return
      if (num.length > this.maxlength) return
      this.$emit('input', num)
    },
    update (value: string): void {
      let num = this.parseNum(value)
      if (num > this.max) num = this.max
      if (num < this.min) num = this.min
      const input: any = this.$refs.num
      input.value = num
      this.$emit('input', num)
    },
    /* keypress (event: any): void {
      console.log(typeof event)
      if (event.target.value.length > this.maxlength || !(/^-?\d+$/.test(event.target.value))) {
        const input: any = this.$refs.num
        input.value = this.parseNum(event.target.value)
        event.preventDefault()
      }
    }, */
    parseNum (value: string): any {
      value = value.replace(/\D/g, '')
      value = value.substring(0, this.maxlength)
      let num: any = parseInt(value)
      if (!(/^-?\d+$/.test(value)) || value === '') num = null
      return num
    },
    focus (): void {
      const input: any = this.$refs.num
      input.focus()
    },
    lengthCut (event: any): void {
      const input: any = this.$refs.num
      let value: string = event.target.value
      if (value.length > this.maxlength) value = value.slice(0, this.maxlength)
      input.value = parseInt(value)
    }
  }
})
